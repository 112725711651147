import React from "react"
import { NavLink, useLocation } from "react-router-dom"
import styled from "styled-components"

import { Theme } from "../theme"


interface TabProps {
  first: boolean,
  last: boolean,
  isSelected: boolean
}


const NavContainer = styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;

  // @media (max-width: 950px) {
  //   width: 100vw;
  //   flex-direction: column;
  // }
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  width: 33.34%;

  // @media (max-width: 950px) {
  //   width: 100%;
  // }
`

const Tab = styled.div<TabProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  border-top-left-radius: ${ props => props.first ? "1.25rem" : "0" };
  border-top-right-radius: ${ props => props.last ? "1.25rem" : "0" };
  font-size: 0.9rem;
  font-weight: ${ props => props.isSelected ? "900" : "300" };
  letter-spacing: 0.2rem;
  color: ${ props => props.theme.colors.text };
  background-color: ${ props => props.isSelected ? props.theme.colors.main : props.theme.colors.tertiary };
  opacity: ${ props => props.isSelected ? "1" : "0.9" };
  transition: font-weight 0.1s ease, background-color 0.1s ease, opacity 0.1s ease;

  &:hover {
    opacity: 1;
  }
`


const Nav: React.FC = () => {
  const location = useLocation()

  return (
    <NavContainer>
      {/* <StyledNavLink to="/stats"><Tab theme={ Theme } first={ true } last={ false } isSelected={ location.pathname.slice(0, 10) === "/stats" }>Statistics</Tab></StyledNavLink> */}
      <StyledNavLink to="/transactions"><Tab theme={ Theme } first={ true } last={ false } isSelected={ location.pathname.slice(0, 13) === "/transactions" }>Transactions</Tab></StyledNavLink>
      <StyledNavLink to="/pairs"><Tab theme={ Theme } first={ false } last={ false } isSelected={ location.pathname.slice(0, 10) === "/pairs" }>Pairs</Tab></StyledNavLink>
      <StyledNavLink to="/tokens"><Tab theme={ Theme } first={ false } last={ true } isSelected={ location.pathname.slice(0, 10) === "/tokens" }>Tokens</Tab></StyledNavLink>
    </NavContainer>
  )
}

export default Nav