import React, { useState, useCallback, useEffect} from "react"

import { Theme } from "../theme"
import { WideDisplayContainer, WideDisplay, Title, DEXInfoLegend, DEXInfoLegendKey, DEXInfoGrid, DEXInfoList, DEXInfoItem, DEXInfoData, PagerRow, PagerButton, PageNumberDisplay, PageNumber, PagerButtonGroup, Icon, StatusLink, ArrowIcon, BackArrowIcon, NextArrowIcon, ConfirmLoader } from "../component-styles"

import { Pair } from "../utils/database-formats"

import { getPairs, sortPages } from "../utils/utils"


const Pairs: React.FC = () => {
  const [ pairs, setPairs ] = useState<Pair[][]>([[]])
  const [ pairsLength, setPairsLength ] = useState<number>(0)
  const [ page, setPage ] = useState<number>(0)
  const [ loading, setLoading ] = useState<boolean>(true)

  const loadDEXPairs = useCallback(async () => {
    setLoading(true)
    const loadedDEXInfos = await getPairs()
    const sortedDEXInfoPages = sortPages(loadedDEXInfos)
    setLoading(false)
    setPairs(sortedDEXInfoPages)
    setPairsLength(loadedDEXInfos.length)
  }, [])

  const handleNextClick = () => {
    if(page === pairs.length - 1) return
    setPage(page + 1)
  }

  const handleBackClick = () => {
    if(page === 0) return
    setPage(page - 1)
  }

  const handleFirstClick = () => {
    setPage(0)
  }

  const handleLastClick = () => {
    setPage(pairs.length - 1)
  }

  useEffect(() => {
    loadDEXPairs()
  }, [loadDEXPairs])


  return (
    <>
      <Title theme={ Theme }>PAIRS</Title>
      <WideDisplayContainer>
        <WideDisplay>
          <DEXInfoLegend theme={ Theme }>
            <DEXInfoLegendKey width={ "8" }></DEXInfoLegendKey>
            <DEXInfoLegendKey width={ "22" }>Token A</DEXInfoLegendKey>
            <DEXInfoLegendKey width={ "22" }>Token B</DEXInfoLegendKey>
            <DEXInfoLegendKey width={ "22" }>Reserve A</DEXInfoLegendKey>
            <DEXInfoLegendKey width={ "22" }>Reserve B</DEXInfoLegendKey>
            <DEXInfoLegendKey width={ "4" }></DEXInfoLegendKey>
          </DEXInfoLegend>
          <DEXInfoGrid>
            <DEXInfoList theme={ Theme }>
              {
                loading
                  ? <DEXInfoItem theme={ Theme }><DEXInfoData width={ "100" }><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/></DEXInfoData></DEXInfoItem>
                  : pairs[page].map((pair) => {
                    return (
                      <DEXInfoItem theme={ Theme } key={ pair.address }>
                        <DEXInfoData width={ "8" }>
                          <Icon src={ `https://api.freemoon.exchange/logos/${ pair.token0.symbol }.png` || require("../images/placeholder.png") } size={ "30" }/>
                          <Icon src={ `https://api.freemoon.exchange/logos/${ pair.token1.symbol }.png` || require("../images/placeholder.png") } size={ "30" }/>
                        </DEXInfoData>
                        <DEXInfoData width={ "22" }>{ pair.token0.symbol }</DEXInfoData>
                        <DEXInfoData width={ "22" }>{ pair.token1.symbol }</DEXInfoData>
                        <DEXInfoData width={ "22" }>{ pair.token0.reserve }</DEXInfoData>
                        <DEXInfoData width={ "22" }>{ pair.token1.reserve }</DEXInfoData>
                        <DEXInfoData width={ "4" }>
                          <StatusLink href={ `https://fsnscan.com/address/${ pair.address }` } theme={ Theme } target="_blank">
                            <ArrowIcon/>
                          </StatusLink>
                        </DEXInfoData>
                      </DEXInfoItem>
                    )
                  })
              }
            </DEXInfoList>
          </DEXInfoGrid>
        </WideDisplay>
      </WideDisplayContainer>

      <PagerRow theme={ Theme }>
        <PagerButtonGroup>
          <PagerButton isActive={ page !== 0 } theme={ Theme } onClick={ handleFirstClick }><BackArrowIcon/><BackArrowIcon/></PagerButton>
          <PagerButton isActive={ page !== 0 } theme={ Theme } onClick={ handleBackClick }><BackArrowIcon/></PagerButton>
        </PagerButtonGroup>
        <PageNumberDisplay>
          <PageNumber>{ (page * 10) + 1 } - { (page * 10) + pairs[page].length }&nbsp;..&nbsp;{ pairsLength }</PageNumber>
        </PageNumberDisplay>
        <PagerButtonGroup>
          <PagerButton isActive={ page !== pairs.length - 1 } theme={ Theme } onClick={ handleNextClick }><NextArrowIcon/></PagerButton>
          <PagerButton isActive={ page !== pairs.length - 1 } theme={ Theme } onClick={ handleLastClick }><NextArrowIcon/><NextArrowIcon/></PagerButton>
        </PagerButtonGroup>
      </PagerRow>
    </>
  )
}

export default Pairs