import React, { useState, useCallback, useEffect} from "react"
import { ethers } from "ethers"

import { Theme } from "../theme"
import { QueryItem, WideDisplayContainer, WideDisplay, Title, CategorySelector, CategoryTab, DEXInfoLegend, DEXInfoLegendKey, DEXInfoGrid, DEXInfoList, DEXInfoItem, DEXInfoData, PagerRow, PagerButton, PageNumberDisplay, PageNumber, PageLoadCap, PagerButtonGroup, Icon, StatusLink, ArrowIcon, BackArrowIcon, NextArrowIcon, DropDownIcon, QueryRow, QueryToken, QueryAccount, ConfirmLoader } from "../component-styles"

import { TransactionInfo, TransactionType, SelectedQueryToken, defaultQueryToken } from "../utils/database-formats"

import { getSwaps, getAdditions, getRemovals, sortPages, formatAccount, formatTime } from "../utils/utils"

import Picker from "../components/Picker"


const Pairs: React.FC = () => {
  const [ transactions, setTransactions ] = useState<TransactionInfo[][]>([[]])
  const [ transactionsLength, setTransactionsLength ] = useState<number>(0)
  const [ page, setPage ] = useState<number>(0)
  const [ txType, setTxType ] = useState<TransactionType>(TransactionType.Swap)
  const [ filterFieldA, setFilterFieldA ] = useState<SelectedQueryToken>(defaultQueryToken)
  const [ filterFieldB, setFilterFieldB ] = useState<SelectedQueryToken>(defaultQueryToken)
  const [ accountFilter, setAccountFilter ] = useState<string>("")
  const [ displayAPicker, setDisplayAPicker ] = useState<boolean>(false)
  const [ displayBPicker, setDisplayBPicker ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ pageLoadCap, setPageLoadCap ] = useState<string>("500")
  const [ docLimit, setDocLimit ] = useState<string>("0")

  const loadSwaps = useCallback(async () => {
    setLoading(true)
    const docLoadLimit = parseInt(docLimit) ? docLimit : "500"
    const validAccount = ethers.utils.isAddress(accountFilter)
    const filter = { from: filterFieldA.symbol, to: filterFieldB.symbol, account: validAccount ? accountFilter : "", docLimit: docLoadLimit }
    const loadedSwaps = await getSwaps(filter)
    const sortedSwapsPages = sortPages(loadedSwaps)
    setTransactions(sortedSwapsPages)
    setTransactionsLength(loadedSwaps.length)
    setLoading(false)
  }, [filterFieldA, filterFieldB, accountFilter, docLimit])

  const loadAdditions = useCallback(async () => {
    setLoading(true)
    const docLoadLimit = parseInt(docLimit) ? docLimit : "500"
    const validAccount = ethers.utils.isAddress(accountFilter)
    const filter = { tokenA: filterFieldA.symbol, tokenB: filterFieldB.symbol, account: validAccount ? accountFilter : "", docLimit: docLoadLimit }
    const loadedAdditions = await getAdditions(filter)
    const sortedAdditionsPages = sortPages(loadedAdditions)
    setTransactions(sortedAdditionsPages)
    setTransactionsLength(loadedAdditions.length)
    setLoading(false)
  }, [filterFieldA, filterFieldB, accountFilter, docLimit])

  const loadRemovals = useCallback(async () => {
    setLoading(true)
    const docLoadLimit = parseInt(docLimit) ? docLimit : "500"
    const validAccount = ethers.utils.isAddress(accountFilter)
    const filter = { tokenA: filterFieldA.symbol, tokenB: filterFieldB.symbol, account: validAccount ? accountFilter : "", docLimit: docLoadLimit }
    const loadedRemovals = await getRemovals(filter)
    const sortedRemovalsPages = sortPages(loadedRemovals)
    setTransactions(sortedRemovalsPages)
    setTransactionsLength(loadedRemovals.length)
    setLoading(false)
  }, [filterFieldA, filterFieldB, accountFilter, docLimit])

  const handleSwapTabClick = () => {
    setTxType(TransactionType.Swap)
    loadSwaps()
  }
   
  const handleAddTabClick = () => {
    setTxType(TransactionType.Addition)
    loadAdditions()
  }
 
  const handleRemoveTabClick = () => {
    setTxType(TransactionType.Removal)
    loadRemovals()
  }

  const handleNextClick = () => {
    if(page === transactions.length - 1) return
    setPage(page + 1)
  }

  const handleBackClick = () => {
    if(page === 0) return
    setPage(page - 1)
  }

  const handleFirstClick = () => {
    setPage(0)
  }

  const handleLastClick = () => {
    setPage(transactions.length - 1)
  }

  const handleAccountChange = (value: string) => {
    setAccountFilter(value)
  }

  const handlePageLoadCapChange = (value: string) => {
    if(value) {
      const valueInt = parseInt(value)
      setPageLoadCap(String(valueInt))
    } else setPageLoadCap("")
  }


  const displayDEXInfoLegend = () => {
    const key1 = txType === TransactionType.Swap ? "From" : "Token A"
    const key2 = txType === TransactionType.Swap ? "To" : "Token B"
    const key3 = txType === TransactionType.Swap ? "Amount In" : "Amount A"
    const key4 = txType === TransactionType.Swap ? "Amount Out" : "Amount B"

    return (
      <DEXInfoLegend theme={ Theme }>
        <DEXInfoLegendKey width={ "8" }></DEXInfoLegendKey>
        <DEXInfoLegendKey width={ "12" }>{ key1 }</DEXInfoLegendKey>
        <DEXInfoLegendKey width={ "12" }>{ key2 }</DEXInfoLegendKey>
        <DEXInfoLegendKey width={ "16" }>{ key3 }</DEXInfoLegendKey>
        <DEXInfoLegendKey width={ "16" }>{ key4 }</DEXInfoLegendKey>
        <DEXInfoLegendKey width={ "16" }>Account</DEXInfoLegendKey>
        <DEXInfoLegendKey width={ "16" }>Time</DEXInfoLegendKey>
        <DEXInfoLegendKey width={ "4" }></DEXInfoLegendKey>
      </DEXInfoLegend>
    )
  }

  useEffect(() => {
    if(txType === TransactionType.Swap) loadSwaps()
  }, [txType, loadSwaps])

  useEffect(() => {
    if(txType === TransactionType.Addition) loadAdditions()
  }, [txType, loadAdditions])


  useEffect(() => {
    if(txType === TransactionType.Removal) loadRemovals()
  }, [txType, loadRemovals])

  useEffect(() => {
    const timer = setTimeout(() => (!loading ? setDocLimit(pageLoadCap) : null), 1000)
    return () => clearTimeout(timer)
  }, [pageLoadCap, loading])


  return (
    <>
      <Title theme={ Theme }>TRANSACTIONS</Title>
      <QueryRow>
        <QueryItem>
          <QueryToken onClick={ () => setDisplayAPicker(true) } isSelected={ Boolean(filterFieldA.name) } theme={ Theme }><Icon src={ filterFieldA.logo } size={ "30" }/>{ filterFieldA.symbol }<DropDownIcon/></QueryToken>
          <QueryToken onClick={ () => setDisplayBPicker(true) } isSelected={ Boolean(filterFieldB.name) } theme={ Theme }><Icon src={ filterFieldB.logo } size={ "30" }/>{ filterFieldB.symbol }<DropDownIcon/></QueryToken>
        </QueryItem>
        <QueryItem>
          <QueryAccount theme={ Theme } spellCheck="false" placeholder="Account 0x..." onChange={ (e) => handleAccountChange(e.target.value) } value={ accountFilter }/>
        </QueryItem>
      </QueryRow>
      <CategorySelector>
        <CategoryTab onClick={ handleSwapTabClick } isActive={ txType === TransactionType.Swap } theme={ Theme }>Swaps</CategoryTab>
        <CategoryTab onClick={ handleAddTabClick } isActive={ txType === TransactionType.Addition } theme={ Theme }>Additions</CategoryTab>
        <CategoryTab onClick={ handleRemoveTabClick } isActive={ txType === TransactionType.Removal } theme={ Theme }>Removals</CategoryTab>
      </CategorySelector>
      <WideDisplayContainer>
        <WideDisplay>
          {
            displayDEXInfoLegend()
          }
          <DEXInfoGrid>
            <DEXInfoList theme={ Theme }>
              {
                loading
                  ? <DEXInfoItem theme={ Theme }>
                      <DEXInfoData width={ "20" }/>
                      <DEXInfoData width={ "20" }>
                        <ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/>
                      </DEXInfoData>
                      <DEXInfoData width={ "20" }/>
                    </DEXInfoItem>
                  : transactions[page].map((tx, i) => {
                    return (
                      <DEXInfoItem theme={ Theme } key={ i }>
                        <DEXInfoData width={ "8" }>
                          <Icon src={ `https://api.freemoon.exchange/logos/${ txType === TransactionType.Swap ? tx.from : tx.tokenA }.png` } size={ "30" }/>
                          <Icon src={ `https://api.freemoon.exchange/logos/${ txType === TransactionType.Swap ? tx.to : tx.tokenB }.png` } size={ "30" }/>
                        </DEXInfoData>
                        <DEXInfoData width={ "12" }>{ txType === TransactionType.Swap ? tx.from : tx.tokenA }</DEXInfoData>
                        <DEXInfoData width={ "12" }>{ txType === TransactionType.Swap ? tx.to : tx.tokenB }</DEXInfoData>
                        <DEXInfoData width={ "16" }>{ txType === TransactionType.Swap ? tx.amountIn : tx.amountA }</DEXInfoData>
                        <DEXInfoData width={ "16" }>{ txType === TransactionType.Swap ? tx.amountOut : tx.amountB }</DEXInfoData>
                        <DEXInfoData width={ "16" }>{ formatAccount(tx.account) }</DEXInfoData>
                        <DEXInfoData width={ "16" }>{ formatTime(tx.timestamp) }</DEXInfoData>
                        <DEXInfoData width={ "4" }>
                          <StatusLink href={ `https://fsnscan.com/tx/${ tx.txHash }` } theme={ Theme } target="_blank">
                            <ArrowIcon/>
                          </StatusLink>
                        </DEXInfoData>
                      </DEXInfoItem>
                    )
                  })
              }
            </DEXInfoList>
          </DEXInfoGrid>
        </WideDisplay>
      </WideDisplayContainer>

      <PagerRow theme={ Theme }>
        <PagerButtonGroup>
          <PagerButton isActive={ page !== 0 } theme={ Theme } onClick={ handleFirstClick }><BackArrowIcon/><BackArrowIcon/></PagerButton>
          <PagerButton isActive={ page !== 0 } theme={ Theme } onClick={ handleBackClick }><BackArrowIcon/></PagerButton>
        </PagerButtonGroup>
        <PageNumberDisplay>
          <PageNumber>{ (page * 10) + 1 } - { (page * 10) + transactions[page].length }&nbsp;..&nbsp;{ transactionsLength }</PageNumber>
          <PageLoadCap type="number" theme={ Theme } placeholder="Max" value={ pageLoadCap } onChange={ (e) => handlePageLoadCapChange(e.target.value) }/>
        </PageNumberDisplay>
        <PagerButtonGroup>
          <PagerButton isActive={ page !== transactions.length - 1 } theme={ Theme } onClick={ handleNextClick }><NextArrowIcon/></PagerButton>
          <PagerButton isActive={ page !== transactions.length - 1 } theme={ Theme } onClick={ handleLastClick }><NextArrowIcon/><NextArrowIcon/></PagerButton>
        </PagerButtonGroup>
      </PagerRow>

      {
        displayAPicker
          ? <Picker onClose={ () => setDisplayAPicker(false) } unavailable={ [filterFieldB.symbol] } setPick={ setFilterFieldA } title="Select Token A"/>
          : ""
      }
 
      {
        displayBPicker
          ? <Picker onClose={ () => setDisplayBPicker(false) } unavailable={ [filterFieldA.symbol]} setPick={ setFilterFieldB } title="Select Token B"/>
          : ""
      }
    </>
  )
}

export default Pairs