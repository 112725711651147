enum TransactionType {
  Swap,
  Addition,
  Removal
}


enum Category {
  All = "Category",
  Spot = "Spot",
  Futures = "Futures",
  Options = "Options"
}


interface TransactionInfo {
  from?: string,
  to?: string,
  amountIn?: string,
  amountOut?: string,
  tokenA?: string,
  tokenB?: string,
  amountA?: string,
  amountB?: string,

  account: string,
  txHash: string,
  timestamp: string
}


interface SelectedQueryToken {
  name: string,
  symbol: string,
  logo: string
}


interface Token {
  name: string,
  symbol: string,
  address: string,
  futures: string[],
  options: string[],
  decimals: {
    spot: number,
    futures: number[],
    options: number[]
  },
  logo: string
}


interface Pair {
  address: string,
  token0: {
    symbol: string,
    decimals: number,
    reserve: string
  },
  token1: {
    symbol: string,
    decimals: number,
    reserve: string
  }
}


const defaultQueryToken: SelectedQueryToken = {
  name: "",
  symbol: "Select",
  logo: require("../images/placeholder.png")
}


interface TokenReserve {
  [key: string]: string
}

export type {
  SelectedQueryToken,
  TransactionInfo,
  Token,
  Pair,
  TokenReserve
}

export {
  TransactionType,
  Category,
  defaultQueryToken
}