import React, { useState, useCallback, useEffect } from "react"

import { Theme } from "../theme"
import { WideDisplayContainer, WideDisplay, Title, DEXInfoGrid, DEXInfoList, DEXInfoItem, DEXInfoLegend, DEXInfoLegendKey, DEXInfoData, Icon, StatusLink, ArrowIcon, PagerRow, PagerButton, PageNumberDisplay, PageNumber, PagerButtonGroup, BackArrowIcon, NextArrowIcon, ConfirmLoader } from "../component-styles"

import { Token, TokenReserve } from "../utils/database-formats"

import { getTokenReserves, getAllTokens, sortPages } from "../utils/utils"


const Tokens: React.FC = () => {
  const [ tokens, setTokens ] = useState<Token[][]>([[]])
  const [ tokensLength, setTokensLength ] = useState<number>(0)
  const [ reserves, setReserves ] = useState<TokenReserve>({})
  const [ page, setPage ] = useState<number>(0)
  const [ loading, setLoading ] = useState<boolean>(true)


  const loadDEXTokens = useCallback(async () => {
    setLoading(true)
    const loadedTokens = await getAllTokens()
    const loadedReserves = await getTokenReserves()
    const sortedTokenPages = sortPages(loadedTokens)
    setLoading(false)
    setReserves(loadedReserves)
    setTokens(sortedTokenPages)
    setTokensLength(loadedTokens.length)
  }, [])

  const handleNextClick = () => {
    if(page === tokens.length - 1) return
    setPage(page + 1)
  }

  const handleBackClick = () => {
    if(page === 0) return
    setPage(page - 1)
  }

  const handleFirstClick = () => {
    setPage(0)
  }

  const handleLastClick = () => {
    setPage(tokens.length - 1)
  }


  useEffect(() => {
    loadDEXTokens()
  }, [loadDEXTokens])


  return (
    <>
      <Title theme={ Theme }>TOKENS</Title>
      <WideDisplayContainer>
        <WideDisplay>
          <DEXInfoLegend theme={ Theme }>
            <DEXInfoLegendKey width={ "4" }></DEXInfoLegendKey>
            <DEXInfoLegendKey width={ "30.67" }>Name</DEXInfoLegendKey>
            <DEXInfoLegendKey width={ "30.67" }>Symbol</DEXInfoLegendKey>
            <DEXInfoLegendKey width={ "30.67" }>DEX Reserve</DEXInfoLegendKey>
            <DEXInfoLegendKey width={ "4" }></DEXInfoLegendKey>
          </DEXInfoLegend>
          <DEXInfoGrid>
            <DEXInfoList theme={ Theme }>
              {
                loading
                  ? <DEXInfoItem theme={ Theme }><DEXInfoData width={ "100" }><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/></DEXInfoData></DEXInfoItem>
                  : tokens[page].map((token) => {
                    return (
                      <DEXInfoItem theme={ Theme } key={ token.address }>
                        <DEXInfoData width={ "4" }>
                          <Icon src={ `https://api.freemoon.exchange/logos/${ token.symbol }.png` || require("../images/placeholder.png") } size={ "30" }/>
                        </DEXInfoData>
                        <DEXInfoData width={ "30.67" }>{ token.name }</DEXInfoData>
                        <DEXInfoData width={ "30.67" }>{ token.symbol }</DEXInfoData>
                        <DEXInfoData width={ "30.67" }>{ reserves[token.symbol] || "0" }</DEXInfoData>
                        <DEXInfoData width={ "4" }>
                          <StatusLink href={ `https://fsnscan.com/address/${ token.address }` } theme={ Theme } target="_blank">
                            <ArrowIcon/>
                          </StatusLink>
                        </DEXInfoData>
                      </DEXInfoItem>
                    )
                  })
              }
            </DEXInfoList>
          </DEXInfoGrid>
        </WideDisplay>
      </WideDisplayContainer>

      <PagerRow theme={ Theme }>
        <PagerButtonGroup>
          <PagerButton isActive={ page !== 0 } theme={ Theme } onClick={ handleFirstClick }><BackArrowIcon/><BackArrowIcon/></PagerButton>
          <PagerButton isActive={ page !== 0 } theme={ Theme } onClick={ handleBackClick }><BackArrowIcon/></PagerButton>
        </PagerButtonGroup>
        <PageNumberDisplay>
          <PageNumber>{ (page * 10) + 1 } - { (page * 10) + tokens[page].length }&nbsp;..&nbsp;{ tokensLength }</PageNumber>
        </PageNumberDisplay>
        <PagerButtonGroup>
          <PagerButton isActive={ page !== tokens.length - 1 } theme={ Theme } onClick={ handleNextClick }><NextArrowIcon/></PagerButton>
          <PagerButton isActive={ page !== tokens.length - 1 } theme={ Theme } onClick={ handleLastClick }><NextArrowIcon/><NextArrowIcon/></PagerButton>
        </PagerButtonGroup>
      </PagerRow>
    </>
  )
}

export default Tokens