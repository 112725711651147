import styled, { DefaultTheme, keyframes } from "styled-components"


interface InputTokenProps {
  theme: DefaultTheme,
  isSelected: boolean
}

interface ActiveElement {
  theme: DefaultTheme,
  isActive: boolean
}

interface SizeProps {
  size: string
}

interface TableLayoutProps {
  width: string
}


const DropDownIcon = () => <span className="material-symbols-outlined">expand_more</span>
const CloseIcon = () => <span className="material-symbols-outlined">close</span>
const ArrowIcon = () => <span className="material-symbols-outlined">arrow_outward</span>
const BackArrowIcon = () => <span className="material-symbols-outlined">arrow_left</span>
const NextArrowIcon = () => <span className="material-symbols-outlined">arrow_right</span>


const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 20px;

  // margin: 10px 0 0;

  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.5rem;

  color: ${props => props.theme.colors.text};

  cursor: default;
`

// const InputToken = styled.button<InputTokenProps>`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   width: 30%;
//   height: 100%;

//   margin: 0 5px 0 0;
//   padding: 0 2px;

//   border: none;
//   outline: 2px solid ${props => props.theme.colors.tertiary};
//   border-radius: 0.4rem;

//   font-family: ${props => props.isSelected ? "Source Code Pro, monospace" : "Noto Sans Display, sans-serif"};
//   font-size: ${props => props.isSelected ? "1.1rem" : "1rem"};
//   font-weight: bold;
//   letter-spacing: ${props => props.isSelected ? "0.08rem;" : "0.15rem"};

//   background-color: ${props => props.theme.colors.secondary};
//   color: ${props => props.theme.colors.text};

//   cursor: pointer;

//   @media (max-width: 700px) {
//     padding: 0;
//     font-size: 1rem;
//   }
// `

const Icon = styled.img<SizeProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  margin: 0 5px;

  // @media (max-width: 700px) {
  //   margin: 0 1%;
  // }
`




const bounceBig = keyframes`
  from {
    transform: translate3d(0, 3px, 0);
  }

  to {
    transform: translate3d(0, -3px, 0);
  }
`

const ConfirmLoader = styled.span`
  display: inline-block;

  width: 9px;
  height: 9px;

  margin: 0 9px;

  border-radius: 50%;

  background-color: ${props => props.theme.colors.text};

  animation: ${bounceBig} 0.6s infinite alternate;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`




// MODAL
const ModalOverlay = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.7);

  z-index: 1000;
`

const ModalTitle = styled.div`
  margin: 0 5px;

  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.5rem;

  color: ${props => props.theme.colors.text};

  cursor: default;
`

const ModalClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 5px;

  color: ${props => props.theme.colors.text};

  cursor: pointer;
`




// PICKER
const PickerPopUp = styled.div`
  // position: fixed;

  position: absolute;
  left: 50%;
  top: 50%; 
  margin-left: -200px;
  margin-top: -350px;

  // right: 50%;

  width: 400px;
  height: 700px;

  // margin: 120px -210px 0 0;
  padding: 0 10px;

  border-radius: 1.25rem;

  background-color: ${props => props.theme.colors.secondary};

  z-index: 1001;

  @media (max-width: 950px) {
    width: 300px;
    height: 325px;
    margin-left: -160px;
    margin-top: -150px;
    overflow-y: scroll;
  }
`

const PickerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 50px;
`

const PickerSearch = styled.input`
  width: 100%;
  height: 50px;

  padding: 0 10px;

  outline: 0px solid ${props => props.theme.colors.tertiary};
  border: none;
  border-radius: 0.4rem;

  font-size: 1.2rem;

  background-color: ${props => props.theme.colors.main};
  color: ${props => props.theme.colors.text};

  transition: outline 0.02s ease;

  &:hover {
    outline: 2px solid ${props => props.theme.colors.tertiary};
  }

  @media (max-width: 950px) {
    width: 96%;
    margin: 0 auto;
    padding: 0 1%;
  }
`

const PickerOptionsList = styled.ul<ActiveElement>`
  list-style: none;

  width: 100%;
  height: 576px;

  margin: 10px 0;
  padding: 0;

  outline: 0px solid ${props => props.theme.colors.tertiary};
  border-radius: 0.4rem;

  overflow-y: scroll;

  transition: outline 0.02s ease;

  &:hover {
    outline: 2px solid ${props => props.isActive ? props.theme.colors.tertiary : "none"};
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.highlight};
    border-radius: 1rem;
  }

  @media (max-width: 950px) {
    width: 98%;
    height: 200px;
    margin: 10px auto;
    padding: 0;
  }
`

const PickerOption = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 96px;

  margin: 0;
  padding: 0;

  font-family: "Source Code Pro", monospace;
  font-size: 1.2rem;

  background-color: ${props => props.theme.colors.main};
  color: ${props => props.theme.colors.text};

  cursor: pointer;

  transition: background-color 0.05s ease;

  &:hover {
    background-color: ${props => props.theme.colors.tertiary};
  }
`

const PickerText = styled.div`
  margin: 0 10px;
`

const PickerTokenListLoading = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  border: none;
  outline: none;
  border-radius: 0.4rem;

  background-color: ${props => props.theme.colors.secondary};
  
  cursor: default;

  transition: outline 0.02s ease;

  @media (max-width: 950px) {
    width: calc(95% - 3px);
  }
`




const StatusLink = styled.a`
  text-decoration: none;

  width: 25px;
  height: 25px;

  color: ${props => props.theme.colors.text};

  cursor: pointer;
`





// DEX Info
const DEXInfoGrid = styled.div`
  margin: 10px 0;
`

const DEXInfoList = styled.ul`
  list-style-type: none;

  width: 100%;
  height: 500px;

  margin: 0;
  padding: 0;

  // overflow: hidden;
  overflow-x: scroll;
`

const DEXInfoItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 99%;
  height: 30px;

  margin: 0 0.5%;
  padding: 10px 0;

  border: none;

  font-family: "Source Code Pro", monospace;
  font-size: 1.05rem;

  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.text};

  cursor: default;

  transition: outline 0.02s ease;

  &:nth-child(2n) {
    background-color: ${ props => props.theme.colors.tertiaryFaint };
  }

  &:nth-child(1) {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }

  &:nth-child(10) {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }

  // @media (max-width: 700px) {
  //   width: calc(95% - 3px);
  //   margin: 2px auto;
  // }
`

const DEXInfoLegend = styled.div`
  display: flex;
  justify-content: space-between;
  width: 99%;
  height: 30px;
  margin: 10px 0.5%;
  background-color: ${ props => props.theme.colors.tertiaryFaint };
  color: ${ props => props.theme.colors.text };
  border-radius: 0.4rem;
  cursor: default;
  font-weight: bold;
`

const DEXInfoLegendKey = styled.div<TableLayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ props => props.width }%;
  letter-spacing: 0.2rem;
`

const DEXInfoData = styled.div<TableLayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ props => props.width }%;

  text-align: center;

  &:nth-child(1) {
    justify-content: flex-start;
  }

  &:nth-last-child(-n) {
    justify-content: flex-end;
  }
`

const PagerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
  height: 30px;
  margin: 10px 0.5%;
  cursor: default;
`

const PagerButton = styled.div<ActiveElement>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  cursor: ${ props => props.isActive ? "pointer" : "default" };
  background-color: ${ props => props.theme.colors.highlight };
  color: ${ props => props.theme.colors.text };
  opacity: ${ props => props.isActive ? "1" : "0.6" };

  border-radius: 0.4rem;
`

const PageNumberDisplay = styled.div`
  display: flex;
`

const PageNumber = styled.div`
  font-size: 1rem;
  font-family: Source Code Pro, monospace;
`

const PageLoadCap = styled.input`
  width: 50px;

  margin: 0 10px;
  outline: none;
  border: none;

  padding: 0 10px;

  outline: 2px solid ${props => props.theme.colors.tertiary};
  border: none;
  border-radius: 0.4rem;

  font-size: 1rem;
  font-family: Source Code Pro, monospace;

  text-align: right;

  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.text};
`

const PagerButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 110px;
  height: 100%;
`

const CategorySelector = styled.div`
  display: flex;
  justify-content: space-between;
  width: 99%;
  height: 30px;
  margin: 20px 0.5%;
  border-radius: 0.4rem;
  cursor: default;
  font-weight: bold;
`

const CategoryTab = styled.div<ActiveElement>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33333%;
  height: 100%;
  background-color: ${ props => props.isActive ? props.theme.colors.tertiary : props.theme.colors.tertiaryFaint };
  color: ${ props => props.theme.colors.text };
  letter-spacing: 0.15rem;

  &:nth-child(1) {
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
  }

  &:nth-child(3) {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }

  cursor: pointer;
`

const QueryRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 99%;
  margin: 0 0.5%;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`

const QueryItem = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 20px 10px 10px;

  @media (max-width: 950px) {
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 10px 2.5%;
  }
`

const QueryToken = styled.button<InputTokenProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 150px;
  height: 50px;

  margin: 0 5px;
  padding: 0 2px;

  border: none;
  outline: 2px solid ${props => props.theme.colors.tertiary};
  border-radius: 0.4rem;

  font-family: ${props => props.isSelected ? "Source Code Pro, monospace" : "Noto Sans Display, sans-serif"};
  font-size: ${props => props.isSelected ? "1.1rem" : "1rem"};
  font-weight: bold;
  letter-spacing: ${props => props.isSelected ? "0.08rem;" : "0.15rem"};

  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.text};

  cursor: pointer;

  // &:nth-child(2) {
  //   margin: 0 10px;
  // }

  // @media (max-width: 700px) {
  //   padding: 0;
  //   font-size: 1rem;
  // }

  @media (max-width: 950px) {
    width: 45%;
    margin: 0;
  }
`

const QueryAccount = styled.input`
  // width: 420px;
  width: 380px;
  height: 50px;

  margin: 0;
  padding: 0 10px;

  outline: 0px solid ${ props => props.theme.colors.tertiary };
  border: 0;
  border-radius: 0.4rem;

  background-color: ${ props => props.theme.colors.secondary };
  color: ${ props => props.theme.colors.text };

  font-size: 1rem;
  font-family: "Source Code Pro", monospace;

  transition: outline 0.02s ease;

  &:hover {
    outline: 2px solid ${props => props.theme.colors.tertiary};
  }

  @media (max-width: 950px) {
    width: 96%;
    margin: 0 auto;
    padding: 0 1%;
  }
`


const CategoryPickerPopUp = styled.div`
  position: fixed;

  right: 50%;

  width: 400px;
  // height: 400px;

  margin: 300px -210px 0 0;
  padding: 0 10px;

  border-radius: 1.25rem;

  background-color: ${props => props.theme.colors.secondary};

  z-index: 1001;

  // @media (max-width: 700px) {
  //   right: 0;
  //   width: 98%;
  //   height: 80%;
  //   margin: 60px 1%;
  //   padding: 0;
  //   overflow-y: scroll;
  // }
`


const CategoryPickerRow = styled.div`
  display: flex;

  margin: 5px 0;

  &:nth-child(1) {
    margin: 10px 0 0;
  }

  &:nth-child(5) {
    margin: 0 0 10px;
  }
`

const CategoryPickerOption = styled.div<InputTokenProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 400px;
  height: 50px;

  border-radius: 0.4rem;

  font-size: 1.1rem;

  background-color: ${ props => props.theme.colors.tertiary };

  cursor: pointer;

  opacity: 0.8;
  
  transition: font-weight 0.1s ease, background-color 0.1s ease, opacity 0.1s ease;

  &:hover {
    opacity: 1;
  }
`

const WideDisplay = styled.div`
  min-width: 950px;
  
  &::-webkit-scrollbar {
    display: none;
  }
`

const WideDisplayContainer = styled.div`
  overflow-x: scroll;
  
  &::-webkit-scrollbar {
    display: none;
  }
`


export {
  DropDownIcon,
  CloseIcon,
  ArrowIcon,
  BackArrowIcon,
  NextArrowIcon,

  Title,
  Icon,

  ConfirmLoader,

  ModalOverlay,
  ModalTitle,
  ModalClose,

  PickerPopUp,
  PickerRow,
  PickerSearch,
  PickerOptionsList,
  PickerOption,
  PickerText,
  PickerTokenListLoading,

  StatusLink,

  DEXInfoGrid,
  DEXInfoList,
  DEXInfoItem,

  DEXInfoLegend,
  DEXInfoLegendKey,
  DEXInfoData,

  PagerRow,
  PagerButtonGroup,
  PagerButton,
  PageNumberDisplay,
  PageNumber,
  PageLoadCap,

  CategorySelector,
  CategoryTab,

  QueryRow,
  QueryItem,
  QueryToken,
  QueryAccount,

  CategoryPickerPopUp,
  CategoryPickerRow,
  CategoryPickerOption,

  WideDisplay,
  WideDisplayContainer,
}