import { useState, useEffect, useRef, useCallback } from "react"
import ReactDOM from "react-dom"

import { SelectedQueryToken, defaultQueryToken } from "../utils/database-formats"

import { getAvailableTokens } from "../utils/utils"

import { Theme } from "../theme"
import { Icon, CloseIcon, ModalOverlay, ModalTitle, ModalClose, PickerPopUp, PickerRow, PickerSearch, PickerOptionsList, PickerOption, PickerText, PickerTokenListLoading, ConfirmLoader } from "../component-styles"


interface Props {
  onClose: () => void,
  unavailable: string[],
  setPick: (selectedToken: SelectedQueryToken) => void,
  title: string
}

const Picker: React.FC<Props> = ({ onClose, unavailable, setPick, title }) => {
  const [ searchValue, setSearchValue ] = useState<string>("")
  const [ list, setList ] = useState<SelectedQueryToken[]>([])
  const [ trimmedList, setTrimmedList ] = useState<SelectedQueryToken[]>([])

  const [ listLoading, setListLoading ] = useState<boolean>(false)



  const overlay = useRef<HTMLDivElement>(null)
  const modal = useRef<HTMLDivElement>(null)



  const exitModal = useCallback((e: MouseEvent) => {
    if(overlay?.current?.contains(e.target as Node) && !modal?.current?.contains(e.target as Node)) {
      onClose()
    }
  }, [onClose])



  useEffect(() => {
    document.addEventListener("mousedown", exitModal)

    return () => {
      document.removeEventListener("mousedown", exitModal)
    }
  }, [exitModal])

  useEffect(() => { 
    const loadTokenList = async () => {
      setListLoading(true)
      const defaultArr = [ defaultQueryToken ]
      const tokens = await getAvailableTokens(unavailable)
      setList(defaultArr.concat(tokens))
      setListLoading(false)
    }

    loadTokenList()
  }, [unavailable])

  useEffect(() => { 
    if(!searchValue) {
      setTrimmedList([])
      return
    }
    let currentOptions = list.filter((tkn: SelectedQueryToken) => {
      const optionNameLower = tkn.name.toLowerCase()
      const optionSymbolLower = tkn.symbol.toLowerCase()
      const searchValueLower = searchValue.toLowerCase()
      return optionNameLower.includes(searchValueLower) || optionSymbolLower.includes(searchValueLower) || tkn.symbol === defaultQueryToken.symbol
    })

    setTrimmedList(currentOptions)
  }, [list, searchValue, onClose, setPick])



  const displayList = () => {
    let listToDisplay = list
    if(trimmedList.length > 0) listToDisplay = trimmedList

    return listToDisplay.map((tkn: SelectedQueryToken) => {
      return (
        <PickerOption
          theme={ Theme }
          key={ tkn.name }
          onClick={() => {
            setPick(tkn)
            setSearchValue("")
            onClose()
          }}>
          <Icon src={ tkn.logo || require("../images/placeholder.png") } size={ "50" }/>
          <PickerText>{ tkn.symbol }</PickerText>
        </PickerOption>
      )
    })
  }



  return ReactDOM.createPortal(
    <ModalOverlay ref={ overlay } theme={ Theme }>
      <PickerPopUp ref={ modal } theme={ Theme }>
        <PickerRow theme={ Theme }>
          <ModalTitle theme={ Theme }>
            { title }
          </ModalTitle>
          <ModalClose onClick={ () => onClose() } theme={ Theme }><CloseIcon/></ModalClose>
        </PickerRow>
        <PickerRow theme={ Theme }>
          <PickerSearch type="text" spellCheck="false" placeholder="Search" onChange={ e => setSearchValue(e.target.value.toLowerCase()) } theme={ Theme }/>
        </PickerRow>
        <PickerOptionsList isActive={ !listLoading } theme={ Theme }>
          { !listLoading ? displayList() : <PickerTokenListLoading theme={ Theme }><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/><ConfirmLoader theme={ Theme }/></PickerTokenListLoading> }
        </PickerOptionsList>
      </PickerPopUp>
    </ModalOverlay>,
    document.getElementById("picker")!
  )
}

export default Picker