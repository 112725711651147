import React, { useEffect } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import styled, { createGlobalStyle } from "styled-components"

import { loadLogos } from "./utils/utils"

import Header from "./components/Header"
import Nav from "./components/Nav"
import Footer from "./components/Footer"

import Stats from "./routes/0_stats"
import Transactions from "./routes/1_transactions"
import Pairs from "./routes/2_pairs"
import Tokens from "./routes/3_tokens"

import { Theme } from "./theme"

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: linear-gradient(to bottom, ${ props => props.theme.colors.secondary }, ${ props => props.theme.colors.main });
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-size: 16px;
    font-family: "Noto Sans Display", sans-serif;
  }

  * {
    transition: background-color 0.25s ease;

    &::selection {
      background-color: ${ props => props.theme.colors.highlight };
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  // background-color: ${ props => props.theme.colors.main };
  // background: linear-gradient(to bottom, ${ props => props.theme.colors.secondary }, ${ props => props.theme.colors.main });
`

const ShadowBackground = styled.div`
  width: 80%;
  box-shadow: 0 0 40px 0 ${ props => props.theme.colors.highlightFaint };
  border-radius: 1.25rem;

  @media (max-width: 950px) {
    width: 100%;
  }
`

const AppMainDisplay = styled.div`
  width: 100%;
  padding: 20px 0 0;
  background: ${ props => props.theme.colors.main };
`

const Spacer = styled.div`
  width: 100%;
  height: 25px;
`

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  // @media (max-width: 950px) {
  //   align-items: flex-start;
  // }
`


const App: React.FC = () => {
  useEffect(() => {
    loadLogos()
  }, [])

  return (
    <>
      <GlobalStyle theme={ Theme }/>
      <AppContainer theme={ Theme }>
          <Header/>
          <Spacer/>
          <BrowserRouter>
            <MainContainer theme={ Theme }>
              <ShadowBackground theme={ Theme }>
              <Nav/>
              <AppMainDisplay theme={ Theme }>
                <Routes>
                  <Route path="/stats" element={ <Stats/> }/>
                  <Route path="/transactions" element={ <Transactions/> }/>
                  <Route path="/pairs" element={ <Pairs/> }/>
                  <Route path="/tokens" element={ <Tokens/> }/>
                  <Route path="/*" element={ <Navigate to="/transactions"/> }/>
                </Routes>
              </AppMainDisplay>
              </ShadowBackground>
            </MainContainer>
          </BrowserRouter>
          <Footer/>
      </AppContainer>
    </>
  )
}

export default App