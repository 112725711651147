import React from "react"
import styled from "styled-components"

import fmnImg from "../images/fmn-blue.png"

import { Theme } from "../theme"


const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  height: 60px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const FreemoonIcon = styled.img`
  width: 50px;
  height: 50px;
  margin: 0 5px;

  @media (max-width: 1000px) {
    width: 40px;
    height: 40px;
    margin: 5px;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px 0 25px;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 1rem;
  color: ${ props => props.theme.colors.text };
  cursor: default;

  @media (max-width: 1000px) {
    font-size: 1rem;
    text-align: center;
  }
`

const Subtitle = styled.div`
  display: flex;
  align-items: flex-end;
  height: 32px;
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
  color: ${ props => props.theme.colors.highlight };
  cursor: default;

  @media (max-width: 1000px) {
    align-items: center;
    font-size: 1rem;
  }
`


const Header: React.FC = () => {
  return (
    <HeaderContainer theme={ Theme }>
      <TitleContainer>
        <FreemoonIcon src={ fmnImg } alt="FMN"/>
        <Title theme={ Theme }>FREEMOON DEX</Title>
        <Subtitle theme={ Theme }>INFO</Subtitle>
      </TitleContainer>
    </HeaderContainer>
  )
}

export default Header