import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"

import { SelectedQueryToken, Token, Pair, TokenReserve, TransactionInfo } from "./database-formats"

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo("en-US")

const getTokenReserves = async (): Promise<TokenReserve> => {
  const tokenReserveListPreLoaded = await fetch("https://api.freemoon.exchange/reserves")
  const tokenReserveList = await tokenReserveListPreLoaded.json()
  const tokenReserves: TokenReserve = {}

  for(let i = 0; i < tokenReserveList.length; i++) {
    tokenReserves[tokenReserveList[i].token] = tokenReserveList[i].reserve
  }

  return tokenReserves
}

const getPairs = async (): Promise<Pair[]> => {
  const pairListPreLoaded = await fetch("https://api.freemoon.exchange/pairs")
  const pairList = await pairListPreLoaded.json()
  return pairList
}

const getTokens = async (): Promise<Token[]> => {
  const tokenListPreLoaded = await fetch("https://api.freemoon.exchange/tokens")
  const tokenList = await tokenListPreLoaded.json()
  return tokenList
}

const getAllTokens = async () => {
  const tokensLoaded = await getTokens()
  const allTokens = []
  const futuresSuffixes = ["-S", "-M", "-L"]
  const optionsSuffixes = ["-CO", "-PO"]
  
  for(let i = 0; i < tokensLoaded.length; i++) {
    allTokens.push({ name: tokensLoaded[i].name, symbol: tokensLoaded[i].symbol, address: tokensLoaded[i].address })

    for(let j = 0; j < tokensLoaded[i].futures.length; j++) {
      if(tokensLoaded[i].futures[j]) {
        const futuresSymbol = tokensLoaded[i].symbol + futuresSuffixes[j]
        allTokens.push({ name: tokensLoaded[i].name, symbol: futuresSymbol, address: tokensLoaded[i].futures[j] })
      }
    }

    for(let j = 0; j < tokensLoaded[i].options.length; j++) {
      if(tokensLoaded[i].options[j]) {
        const optionsSymbol = tokensLoaded[i].symbol + optionsSuffixes[j]
        allTokens.push({ name: tokensLoaded[i].name, symbol: optionsSymbol, address: tokensLoaded[i].options[j] })
      }
    }
  }

  return allTokens
}

const loadLogos = async () => {
  const tokens = await getTokens()
  const logos = []
  for(let i = 0; i < tokens.length; i++) {
    let img = new Image()
    img.src = `https://api.freemoon.exchange/logos/${ tokens[ i ].logo }`
    logos.push(img)
  }
}

const getContext = async () => {
  const contextLoaded = await fetch("https://api.freemoon.exchange/context")
  const context = contextLoaded.json()
  return context
}

const getAvailableTokens = async (unavailable: string[]): Promise<SelectedQueryToken[]> => {
  const futuresSuffixes = ["-S", "-M", "-L"]
  const optionsSuffixes = ["-CO", "-PO"]
  const tokensLoaded = await getTokens()
  const availableTokens: SelectedQueryToken[] = []
  for(let i = 0; i < tokensLoaded.length; i++) {    
    for(let j = 0; j < unavailable.length; j++) { 
      if(tokensLoaded[i].symbol !== unavailable[j]) {
        availableTokens.push({
          name: tokensLoaded[i].name,
          symbol: tokensLoaded[i].symbol,
          logo: `https://api.freemoon.exchange/logos/${ tokensLoaded[i].symbol }.png`
        })
      }

      for(let k = 0; k < tokensLoaded[i].futures.length; k++) {
        const futuresSymbol = tokensLoaded[i].symbol + futuresSuffixes[k]
        if(tokensLoaded[i].futures[k] && futuresSymbol !== unavailable[j]) {
          availableTokens.push({
            name: tokensLoaded[i].name,
            symbol: futuresSymbol,
            logo: `https://api.freemoon.exchange/logos/${ futuresSymbol }.png`
          })
        }
      }

      for(let k = 0; k < tokensLoaded[i].options.length; k++) {
        const optionsSymbol = tokensLoaded[i].symbol + optionsSuffixes[k]
        if(tokensLoaded[i].options[k] && optionsSymbol !== unavailable[j]) {
          availableTokens.push({
            name: tokensLoaded[i].name,
            symbol: optionsSymbol,
            logo: `https://api.freemoon.exchange/logos/${ optionsSymbol }.png`
          })
        }
      }
    }
  }

  return availableTokens
}

const sortPages = (items: any[]) => {
  const remainder = items.length % 10
  const incompletePages = remainder >= 1 ? 1 : 0
  const pages = items.length >= 10 ? ((items.length - remainder) / 10) + incompletePages : 1
  const sortedPages = []

  for(let i = 0; i < pages; i++) {
    const pageStart = (i * 10)
    const pageEnd = items.length < pageStart + 10 ? pageStart + remainder : pageStart + 10
    const onThisPage = items.slice(pageStart, pageEnd)
    sortedPages.push(onThisPage)
  }

  return sortedPages
}


const formatAccount = (account: string): string => {
  const formattedAccount = account.slice(0, 7) + ".." + account.slice(40, account.length)
  return formattedAccount
}


const formatTime = (ts: string): string => {
  const formattedTime = timeAgo.format(Number(ts) * 1000)
  return formattedTime
}


const getSwaps = async (filter: { from: string, to: string, account: string }): Promise<TransactionInfo[]> => {
  if(filter.from === "Select") filter.from = ""
  if(filter.to === "Select") filter.to = ""
  const swapsLoaded = await fetch("https://api.freemoon.exchange/swaps", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(filter) })
  const swaps = await swapsLoaded.json()
  return swaps
}


const getAdditions = async (filter: { tokenA: string, tokenB: string, account: string }): Promise<TransactionInfo[]> => {
  if(filter.tokenA === "Select") filter.tokenA = ""
  if(filter.tokenB === "Select") filter.tokenB = ""
  const additionsLoaded = await fetch("https://api.freemoon.exchange/additions", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(filter) })
  const additions = await additionsLoaded.json()
  return additions
}


const getRemovals = async (filter: { tokenA: string, tokenB: string, account: string }): Promise<TransactionInfo[]> => {
  if(filter.tokenA === "Select") filter.tokenA = ""
  if(filter.tokenB === "Select") filter.tokenB = ""
  const removalsLoaded = await fetch("https://api.freemoon.exchange/removals", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(filter) })
  const removals = await removalsLoaded.json()
  return removals
}


export {
  getTokens,
  getPairs,
  getTokenReserves,
  getAllTokens,

  loadLogos,
  getContext,
  getAvailableTokens,

  sortPages,
  formatAccount,
  formatTime,

  getSwaps,
  getAdditions,
  getRemovals,
}