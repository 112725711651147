import React, { useState, useCallback, useEffect} from "react"
import styled from "styled-components"

import { getContext } from "../utils/utils"

import { Theme } from "../theme"
import { Icon } from "../component-styles"

import FUSION from "../images/fsn-192x192.png"



const FooterContainer = styled.footer`
  // position: relative;
  // bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;

  @media (max-width: 950px) {
  }
`

const FooterCentered = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  @media (max-width: 950px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: 100px;
    margin: 50px 0 0;
  }
`

const FooterInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
  height: 100%;
  margin: 0 10px;
  border-radius: 0.3125rem;

  @media (max-width: 950px) {
    margin: 5px;
    justify-content: flex-start;
  }
`

const PoweredBy = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  height: 100%;
  margin: 0 10px;
  font-family: "Noto Sans Display", sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
  color: ${ props => props.theme.colors.text };
  cursor: default;

  @media (max-width: 950px) {
    width: 250px;
    margin: 5px;
    justify-content: flex-start;
  }
`

const Fusion = styled.a`
  cursor: pointer;
  color: ${ props => props.theme.colors.highlight };
`



const Footer: React.FC = () => {
  const [ lastUpdate, setLastUpdate ] = useState<string>("-")

  const getLastUpdateTime = useCallback(async () => {
    const { lastUpdateBlockNumber } = await getContext()
    setLastUpdate(lastUpdateBlockNumber)
  }, [])

  useEffect(() => {
    getLastUpdateTime()
  }, [getLastUpdateTime])

  return (
    <FooterContainer>
      <FooterCentered>
        <PoweredBy theme={ Theme }>powered by <Icon src={ FUSION } size={ "25" }/><Fusion href="https://fusion.org" target="_blank" theme={ Theme }>FUSION</Fusion></PoweredBy>
        <FooterInfo theme={ Theme }>Last updated at block&nbsp;<Fusion href={ `https://fsnscan.com/block/${ lastUpdate }` } target="_blank" theme={ Theme }>{ lastUpdate }</Fusion></FooterInfo>
      </FooterCentered>
    </FooterContainer>
  )
}

export default Footer